@import "app/global.scss";
.home {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  .home_body {
    width: 986px;
    display: flex;
    flex-direction: column;

    .home_body_hot {
      width: 100%;
      height: 48px;
      display: flex;
      align-items: center;
      margin-top: 12px;
      margin-left: 20px;
      font-weight: 600 !important;
      font-size: 16px;
      color: #999999;
    }

    .home_body_content {
      display: flex;
      justify-content: space-between;
      margin-top: 56px;
    }
  }
}
